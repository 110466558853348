import { createCachedSelector, DuckSelector } from '@atc/modular-redux';

import { resultsDuckCreator } from 'axl-ducks';

import { srpDuckPreset } from '@/ducks/presets';

export default resultsDuckCreator({
    ...srpDuckPreset,
    store: 'spotlight',
}).extend({
    selectors: () => ({
        getIndividualSpotlight: new DuckSelector((selectors) => createCachedSelector(
            selectors.getActiveInventory,
            (state, index) => index,
            (activeInventory = [], index) => activeInventory[index]
        )((state, index) => index || 'default')),
    }),
});
