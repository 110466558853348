import { fetchJSON } from '@bonnet/next/fetch';

export default (host, query) => {

    const errorObject = {
        error: 'Circuit Breaker for /credit/decision is open',
        id: {},
    };

    const options = {
        circuitBreaker: {
            id: 'creditDecision',
            timeout: 5000,
            resetTimeout: 300000,
            fallback: errorObject,
        },
        query,
    };
    options.headers = {
        'X-fwd-svc': 'atc',
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };

    options.credentials = 'omit';

    return fetchJSON(`${host}/my-wallet/credit/decision`, options);
};
