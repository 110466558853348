import { createAction, DuckSelector, objectDuckCreator } from '@atc/modular-redux';

import { srpDuckPreset } from '@/ducks/presets';

const srpSelectedFiltersInOrderDuck = objectDuckCreator({
    ...srpDuckPreset,
    store: 'selectedFiltersInOrder',
    initialState: {
        filterChipsInOrder: [],
    },
}).extend({
    types: [
        'SET_SELECTED_FILTERS_IN_ORDER',
    ],

    reducer: (state, action, { types }) => {
        switch (action.type) {
            case types.SET_SELECTED_FILTERS_IN_ORDER: {
                return {
                    ...state,
                    filterChipsInOrder: [...action.payload.filterChipsInOrder],
                };
            }

            default:
                return state;
        }
    },

    creators: ({ types }) => ({
        setSelectedFiltersInOrder: createAction(types.SET_SELECTED_FILTERS_IN_ORDER),
    }),

    selectors: () => ({
        getFilterChipsInOrder: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).filterChipsInOrder),
    }),
});

export default srpSelectedFiltersInOrderDuck;
