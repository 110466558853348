import dealXgFetcher from '@/fetchers/dealXgFetcher';

const getDealDetails = async ({ dealXgHost, id, walletId }) => {

    const dealDetails = await dealXgFetcher({ host: dealXgHost, id, walletId });

    if (dealDetails?.error || typeof dealDetails === 'string') {
        // eslint-disable-next-line no-console
        console.log('[Deal Xg Service] - ', dealDetails?.error || dealDetails);
        return [];
    }

    return dealDetails;
};

// NOTE: Resets all of the notifications for deals passed in
export const resetDealNotifications = (dealXgHost, dealIds) => {
    dealIds.map((id) => dealXgFetcher({ host: dealXgHost, id, endpoint: 'status' }));
};

export default getDealDetails;
