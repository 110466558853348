import { fetchJSON } from '@bonnet/next/fetch';

/*
* @description Deal xg fetcher
* @endpoint /id/{id} - Gets deal data using the deal id
* @endpoint /deal?walletid={walletId} - Gets all deals by associated walletId
* @endpoint /status - Resets a deals notifications by passing the deal id (no body needed)
*/
export default ({ host, body, id = '', endpoint, walletId }) => {

    let path = host + '/my-wallet/dealxg';

    if (walletId) {
        path += `/deal?walletid=${walletId}`;
    } else {
        path += `${id}${endpoint ? '/' + endpoint : ''}`;
    }

    let options = {
        headers: {
            'X-fwd-svc': 'atc',
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        credentials: 'omit',
        circuitBreaker: {
            id: 'dealxg',
            timeout: 5000,
            resetTimeout: 300000,
            fallback: {
                error: 'Circuit Breaker for /dealxg is open',
            },
            // This will avoid the circuit breaker to open,
            // useful when deal is just created trying to fetch deal details
            errorFilter: (error) => error?.status === 404,
        },
        retryOn: [404],
        retryDelay: 250,
    };

    if (endpoint === 'status') {
        options = {
            ...options,
            method: 'PATCH',
        };

        // NOTE: Allows the option to pass a specific field to to the endpoint (Example: testDriveComplete)
        // If no body, the service assumes all flags need to be reset (Example: paymentChangeFlag, etc...)
        if (body) {
            options.body = body;
        }
    }

    return fetchJSON(path, options)
        .then((dealDetails) => {
            if (dealDetails?.error) {
                // eslint-disable-next-line no-console
                console.log('[Deal-Receipt-Fetch-Error]: ', dealDetails.error);
                return [];
            }
            return walletId ? dealDetails : [dealDetails];
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.log('[Deal-Receipt-Fetch-Error]: ', error);
        });
};
