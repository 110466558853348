import { fetchJSON } from '@bonnet/next/fetch';

export default (endpoint, options = {}) => fetchJSON(`/cars-for-sale/vrs${endpoint}`, {
    ...options,
    circuitBreaker: {
        id: 'vrsCircuitBreaker',
        timeout: 5000,
        resetTimeout: 300000,
        fallback: {},
    },
});
