import { fetchJSON } from '@bonnet/next/fetch';

export default (query) => {
    const errorObject = {
        error: 'Circuit Breaker for /ico/offers is open',
    };

    const options = {
        circuitBreaker: {
            id: 'ico',
            timeout: 5000,
            resetTimeout: 30000,
            fallback: errorObject,
        },

    };
    return fetchJSON('/cars-for-sale/ico/offers/v1/offers', { query, ...options });
};
