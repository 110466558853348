import {
    createCachedSelector,
    createSelector,
    DuckSelector,
} from '@atc/modular-redux';

import { resultsDuckCreator } from 'axl-ducks';

import { srpDuckPreset } from '@/ducks/presets';

const DEFAULT_EMPTY_ARRAY = [];

const srpNewCarBoostDuck = resultsDuckCreator({
    ...srpDuckPreset,
    store: 'boost',
}).extend({
    selectors: () => ({
        getAvailableInventory: new DuckSelector((selectors) => createSelector(
            selectors.getActiveInventory,
            (inventory) => inventory || DEFAULT_EMPTY_ARRAY
        )),
        getIndividualListing: new DuckSelector((selectors) => createCachedSelector(
            selectors.getActiveInventory,
            (state, index) => index,
            (activeInventory = [], index) => activeInventory[index]
        )((state, index) => index || 'default')),
    }),
});

export default srpNewCarBoostDuck;
