import { fetchJSON } from '@bonnet/next/fetch';

export default (pixAllId, debug, stillInterestedFetch = false) => {
    const options = {
        circuitBreaker: {
            id: 'cbh',
            timeout: 1000,
            resetTimeout: 300000,
            fallback: {},
        },
    };

    let queryParams = 'own';

    if (stillInterestedFetch) {
        queryParams += ',search,vlp,vdp,other&drevents=true&num_records=300&still_interested=true';
    }

    const endpoint = '/cars-for-sale/cbh/history/' + pixAllId;
    const queryString = `?pagetypefilter=${queryParams}` + (debug ? '&debug=true' : '');
    return fetchJSON(endpoint + queryString, options);
};
