import { fetchJSON } from '@bonnet/next/fetch';

import { ClientAuth } from 'reaxl-auth';

export default async (data) => fetchJSON('/account-api/lightuser/vehicle', {
    body: {
        bookmarkTitle: data?.bookmarkTitle,
        email: data?.emailAddress,
        listingId: data?.listingId,
        mobilePhone: data?.mobilePhone,
        ownerId: data?.ownerId,
    },
    credentials: 'omit',
    headers: {
        ...(await ClientAuth.getHeaderAuthToken()),
    },
    method: 'POST',
});
