import { birfDuck } from '@/ducks';

// Ensure this middleware has been called after the all redux dispatches
// as it requires knowledge of finalized redux state
export default function withDataIslandDispatcher() {
    return async (ctx) => {

        // extract only the required data from ctx.data
        // previous code was passing the whole data object that may contain unserializable data
        const {
            pageData,
            dataIsland,
        } = ctx.data;

        await ctx.store.dispatch(birfDuck.creators.setState({
            pageData,
            dataIsland,
        }));
    };
}
