import { fetchJSON } from '@bonnet/next/fetch';

export default (data = {}) => {
    const options = {
        body: data,
        method: 'POST',
        circuitBreaker: {
            id: 'creditverification',
            timeout: 15000, // 15 seconds timeout to allow for lambda cold start
            resetTimeout: 10000, // reduce to 10 seconds so that user can resubmit after making changes
            fallback: {},
        },
        headers: {
            'Content-Type': 'text/plain',
        },
    };

    const endpoint = '/cars-for-sale/creditverification';

    return fetchJSON(endpoint, options);
};
