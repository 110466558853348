export default function dataLayerTransform(currentPageName, showNoResults, expiredVdp) {
    let siteSection = '';
    let pageType = '';
    let detailPageName = '';

    switch (currentPageName) {
        case 'srp': {
            siteSection = 'find-your-car';
            pageType = showNoResults ? 'srp_no_results' : 'srp';
            detailPageName = showNoResults ? 'srp_no_results' : 'srp';
            break;
        }

        case 'vdp': {
            siteSection = 'find-your-car';
            pageType = expiredVdp ? 'vdp_exp' : 'vdp';
            detailPageName = expiredVdp ? 'vdp_exp' : 'vdp';
            break;
        }

        case 'sfp': {
            siteSection = 'find-your-car';
            pageType = 'asf';
            detailPageName = 'asf';
            break;
        }

        case 'fordlanding': {
            siteSection = 'find-your-car';
            pageType = 'slp_fba';
            detailPageName = 'slp_fba';
            break;
        }

        case 'bap': {
            siteSection = 'find-your-car';
            pageType = 'build-and-price';
            detailPageName = 'build-and-price';
            break;
        }

        default:
    }

    return {
        siteSection,
        pageType,
        detailPageName,
    };
}
