import _get from 'lodash/get';

import { createSelector, DuckSelector } from '@atc/modular-redux';

import { paginationDuckCreator } from 'axl-ducks';

import { srpDuckPreset } from '@/ducks/presets';

const srpPaginationDuck = paginationDuckCreator({
    ...srpDuckPreset,
}).extend({
    selectors: (duck) => ({
        getFirstRecord: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            (localState) => (localState.currentPage - 1) * localState.numRecords,
        )),
        getCurrentPage: new DuckSelector((selectors) => (state) => _get(selectors.getDuckState(state), 'currentPage')),
        getNumRecords: new DuckSelector((selectors) => (state) => _get(selectors.getDuckState(state), 'numRecords')),
    }),
});

export default srpPaginationDuck;
