import { DuckSelector } from '@atc/modular-redux';

import { resultsDuckCreator } from 'axl-ducks';

import { srpDuckPreset } from '@/ducks/presets';

const srpResultsDuck = resultsDuckCreator({
    ...srpDuckPreset,
}).extend({
    selectors: (duck) => ({
        getInitialLoad: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).initialLoad),
    }),
});

export default srpResultsDuck;
